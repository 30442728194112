:root {
  --vh: 1vh;
}

.home-page {
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.logo {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 500px;
  height: 152px;
  z-index: 3;
}

.subtitle {
  position: absolute;
  top: calc(40px + 152px + 10px);
  left: 40px;
  font-family: 'Nourd-Medium', sans-serif;
  font-size: 40px;
  color: #FFF8EF;
  z-index: 3;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 10px;
  width: 500px;
}

.social-media-icons {
  position: absolute;
  bottom: calc(70px);
  left: 40px;
  display: flex;
  gap: 20px;
  z-index: 3;
}

.social-media-icons img {
  width: 80px;
  height: 80px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/Background.png');
  background-size: cover;
  background-position: center;
  z-index: 1;
}



 .centered-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  animation: moveUp 2s forwards;
  z-index: 2;
  /* object-fit: cover;  */
  object-position: center; 
 }

 /* .centered-image {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  animation: moveUp 2s forwards;
  z-index: 2;
  object-fit: cover; 
  object-position: center; 
} */

@keyframes moveUp {
  from {
    transform: translateY(5%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #223631;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 0 40px;
  font-size: 14px;
  z-index: 10;
}

.footer-left {
  font-size: 21px;
  font-family: 'Nourd-Medium', sans-serif;
  padding-left: 40px;
  color: #FA9E93;
}

.footer-right {
  font-size: 12px;
  font-family: 'Nourd-Regular', sans-serif;
  padding-right: 40px;
  color: #FFF8EF;
}

@media (min-width: 769px) {
  .footer {
    height: 44px;
  }
}

@media (max-width: 768px) {
  .footer {
    height: 70px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0;
  }

  .footer-left {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    font-family: 'Nourd-Medium', sans-serif;
    color: #FA9E93;
  }

  .footer-right {
    margin-top: 0;
    font-size: 11px;
    font-family: 'Nourd-Regular', sans-serif;
    color: #FFF8EF;
    padding: 10px;
    text-align: center;
  }

  .logo {
    width: auto;
    height: 92px;
    left: 50%;
    transform: translateX(-50%);
  }

  .subtitle {
    top: calc(40px + 92px);
    font-size: 25px;
    left: 50%;
    transform: translateX(-50%);
    letter-spacing: 5px;
    width: 100%;
    text-align: center;
  }

  /* .centered-image {
    width: 100%;
    height: 55vh;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    bottom: 160px;
    opacity: 0;
    animation: moveUp 2s forwards;
    z-index: 2;
    object-fit: unset;
  } */
  .centered-image {
    width: 100%;
    height: 55vh; /* Ensure the image covers the full height of the viewport */
    position: absolute;
    bottom: 160px;
    left: 0;
    opacity: 0;
    animation: moveUp 2s forwards;
    z-index: 2;
    object-fit: cover; /* Crop the image to fit the container */
    object-position: top right; /* Center the image within the container */
  }
  

  .social-media-icons {
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    gap: 15px;
    z-index: 3;
  }

  .social-media-icons img {
    width: 60px;
    height: 60px;
  }
}

@font-face {
  font-family: 'Nourd-Medium';
  src: url('./Nourd/nourd_medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nourd-Regular';
  src: url('./Nourd/nourd_regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
